








































































import Dates from '../../mixins/dates';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import Trademark from '@/models/Trademark';

@Component
export default class MutationDetail extends Mixins(Dates) {
    @Prop({ required: true })
    trademark!: Trademark;

    get nameAndAddress() {
        return this.trademark.nameAddressData;
    }

    get goodsAndServicesComment() {
        return this.trademark.goodsAndServices ? this.trademark.goodsAndServices.comment : null;
    }

    get depotNumbers() {
        if (!this.trademark.trademarkList) {
            return [];
        }

        return this.trademark.trademarkList.map(trademark => trademark.registrationNumber).sort((a, b) => a - b);
    }

    depotLink(id) {
        return '?app=%2Fitem%2F' + id;
    }
}
