<template>
    <span />
</template>

<script>
import Bugsnag from '@bugsnag/js';

export default {
    name: 'RouteNotFound',

    created() {
        if (process.env.VUE_APP_BUGSNAG_KEY) {
            Bugsnag.notify('Unable to find route');
        }
        this.$router.replace({ name: 'simple-search' });
    },
};
</script>
