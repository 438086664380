












































































































































































































































import Images from '../../mixins/images';
import Dates from '../../mixins/dates';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import Trademark from '@/models/Trademark';
import NiceClass from '@/models/NiceClass';

@Component
export default class TrademarkDetail extends mixins(Images, Dates) {
    @Prop() trademark: Trademark;

    get publicationDateRegistration() {
        if (this.trademark.publications) {
            let publication = this.trademark.publications.find(publication => publication.section === 'Registration');

            return publication ? publication.date : null;
        }

        return null;
    }

    get publicationDateRenewal() {
        if (this.trademark.publications) {
            const reversedPublications = [...this.trademark.publications].reverse();
            let publication = reversedPublications.find(publication => publication.section === 'Renewal');

            return publication ? publication.date : null;
        }

        return null;
    }

    get orderedNiceClasses(): [NiceClass] {
        return [...this.trademark.goodsAndServices.niceClasses].sort((a, b) => (a.number > b.number) ? 1 : -1) as [NiceClass];
    }
}
